header {
  height: 100vh;
  padding-top: 5rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ================ CTA ================== */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ================ SOCIALS ================== */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ================ My Picture ================== */

.me {
  /* background: var(--color-primary); */
  background: linear-gradient(var(--color-primary), transparent);
  width: 20rem;
  height: 20rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 10rem 10rem 10rem 10rem;
  border: 5px solid var(--color-bg);
  overflow: hidden;
  padding: 0 2rem 0 1.5rem;
}

/* ================ Scroll Dowwn ================== */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ============ Media Queries (medium screen) =============== */

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* ============ Media Queries (small screen) =============== */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header__socials,
  .scroll_down {
    display: none;
  }
}
